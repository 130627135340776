#testimonial_section {
    width: 100%;
    height: 120vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(to right, #1275BB, #083555);
    overflow: hidden;
    padding: 50px 20px 20px 20px;
}

#test_sbhdng {
    color: white;
    font-size: 20px;
    font-family: 'poppins';
    font-weight: 600;
}

#test_hdng {
    color: white;
    font-size: 50px;
    font-family: 'raleway';
    font-weight: 800;
}

#carosel_container {
    width: 100%;
    padding: 40px 0px 20px 0px;
}

#test_btn {
    width: 150px;
    height: 40px;
    color: white;
    font-size: 14px;
    font-family: 'poppins';
    font-weight: 300;
    background-color: transparent;
    border: solid 2px white;
    padding: 0;
    margin: 0;
    transition: 0.3s ease-in-out;
}

#test_btn #link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: white;
    margin: 0;
    padding: 0;
    padding: 7px 5px;
    transition: 0.2s ease;
}

#test_btn:hover {
    background-color: white;
    color: #083555;
}


#test_btn #link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: white;
    margin: 0;
    padding: 0;
    padding: 7px 15px;
    transition: 0.2s ease;
}

#test_btn:hover #link {
    color: #083555;
    width: 100%;
    height: 100%;
}


@media screen and (max-width: 450px) {

    #testimonial_section {
        width: 100%;
        height: 80vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: linear-gradient(to right, #1275BB, #083555);
        overflow: hidden;
        padding: 50px 10px 20px 10px;
    }

    #test_sbhdng {
        color: white;
        font-size: 18px;
        font-family: 'poppins';
        font-weight: 600;
    }

    #test_hdng {
        color: white;
        font-size: 35px;
        font-family: 'raleway';
        font-weight: 800;
        text-align: center;
    }

    #carosel_container {
        width: 100%;
        padding: 30px 0px 20px 0px;
    }

    #test_btn {
        width: 150px;
        height: 40px;
        color: white;
        font-size: 14px;
        font-family: 'poppins';
        font-weight: 400;
        background-color: transparent;
        border: solid 2px white;
        transition: 0.3s ease-in-out;
    }

    #test_btn:hover {
        background-color: white;
        color: #083555;
    }

}
#park_edge_bg {
    width: 100%;
    height: 100vh;
    /* background-image: url(./211012_View-13-1620x832\ \(1\).jpg); */
}

#park_edge_home {
    width: 100%;
    height: 100%;
    background-color: rgba(11, 11, 11, 0.605);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#park_edge_main_hdng {
    color: whitesmoke;
    font-size: 170px;
    font-family: 'poppins';
    font-weight: 600;
}

#park_edge_main_txt {
    color: white;
    font-size: 20px;
    font-weight: 400;
    font-family: 'poppins';
}

.project_btn {
    background-color: whitesmoke;
    color: #1D1E20;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    font-family: 'poppins';
    margin-top: 70px;
    transition: 0.2s ease;
}

.project_btn:hover {
    background-color: #1D1E20;
    color: white;
}



#park_edge_about {
    width: 100%;
    height: 60vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 80px;
}

#park_edge_about_hdng {
    color: #1D1E20;
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 45px;
    font-weight: 400;
    font-family: 'poppins';
    text-align: center;
}

#park_edge_about_txt {
    color: #1D1E20;
    width: 45%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 16px;
    font-weight: 400;
    font-family: 'poppins';
    gap: 40px;
}



#park_edge_features {
    width: 100%;
    height: 85vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15vw;
    background-color: #f7f7f7;
}

#park_edge_features_div {
    color: #1D1E20;
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 50px;
    padding-top: 60px;
}

#park_edge_features_hdng {
    font-size: 45px;
    font-weight: 400;
    font-family: 'poppins';
}

#park_edge_features_points {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 15px;
}

#park_edge_payment_plan_div {
    /* width: 45%; */
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#park_edge_payment_btn {
    background-color: #1D1E20;
    color: whitesmoke;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    font-family: 'poppins';
    margin-top: 30px;
    transition: 0.2s ease;
}

#park_edge_payment_btn:hover {
    background-color: white;
    color: #1D1E20;
}



#park_edge_amenities {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
}

#park_edge_amenities_img {
    background-image: url(https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=503,h=622,fit=crop/YBg4JBL97EcZG7Zq/3-1-AR0b9NjXkXF5DO21.jpeg);
    background-size: cover;
    width: 40%;
    height: 100%;
}

#park_edge_amenities_content {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    font-family: 'poppins';
    padding: 30px 60px;
    text-align: center;
    color: #1D1E20;
    gap: 50px;
}

#park_edge_amenities_icn_div {
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* Ensures that items are spaced out evenly */
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    /* Sets a gap of 20px between the items */
}

#park_edge_amenities_icn_div>span {
    flex-basis: calc(33.333% - 20px);
    /* Subtract the gap to ensure proper spacing */
    display: flex;
    justify-content: center;
    /* Center content within each item if necessary */
    align-items: center;
}



#park_edge_contact {
    width: 100%;
    height: 75vh;
    background-color: #1D1E20;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

}

#park_edge_contact_content {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
}

#park_edge_contact_hdng {
    color: #f7f7f7;
    font-size: 45px;
    font-weight: 400;
    font-family: 'poppins';
    text-align: center;
}

#park_edge_contact_txt {
    color: #f7f7f7;
    font-size: 16px;
    font-weight: 300;
    font-family: 'poppins';
}

hr {
    width: 90%;
    background-color: #f7f7f7;
    height: 7px;
    opacity: 1;
    margin-top: 17%;
}

#park_edge_social_div_1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    color: #f7f7f7;
    font-size: 15px;
    font-weight: 300;
    font-family: 'poppins';
}

#park_edge_social_div_2 {
    display: none;
}

#park_edge_contact_form {
    width: 30%;
}




@media screen and (max-width: 450px) {

    #park_edge_bg {
        width: 100%;
        height: 80vh;
        /* background-image: url(./211012_View-13-1620x832\ \(1\).jpg); */
        background-size: cover;
    }

    #park_edge_home {
        width: 100%;
        height: 100%;
        background-color: rgba(11, 11, 11, 0.605);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #park_edge_main_hdng {
        color: whitesmoke;
        font-size: 70px;
        font-family: 'poppins';
        font-weight: 600;
    }

    #park_edge_main_txt {
        color: white;
        font-size: 20px;
        font-weight: 400;
        font-family: 'poppins';
    }

    .project_btn {
        background-color: whitesmoke;
        color: #1D1E20;
        border: none;
        padding: 10px 20px;
        font-size: 14px;
        font-weight: 500;
        font-family: 'poppins';
        margin-top: 70px;
        transition: 0.2s ease;
    }

    .project_btn:hover {
        background-color: #1D1E20;
        color: white;
    }



    #park_edge_about {
        width: 100%;
        height: 80vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 50px;
        overflow: hidden;
        padding: 30px 20px;
    }

    #park_edge_about_hdng {
        color: #1D1E20;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        font-weight: 400;
        font-family: 'poppins';
        text-align: center;
        line-height: 1.2;
    }

    #park_edge_about_txt {
        color: #1D1E20;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        font-size: 16px;
        font-weight: 400;
        font-family: 'poppins';
        gap: 40px;
    }



    #park_edge_features {
        width: 100%;
        height: 115vh;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        gap: 10vh;
        background-color: #f7f7f7;
        padding: 20px;
    }

    #park_edge_features_div {
        color: #1D1E20;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 30px;
        padding-top: 20px;
    }

    #park_edge_features_hdng {
        font-size: 35px;
        font-weight: 400;
        font-family: 'poppins';
    }

    #park_edge_features_points {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 15px;
    }

    #park_edge_payment_plan_div {
        /* width: 45%; */
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    #park_edge_payment_btn {
        background-color: #1D1E20;
        color: whitesmoke;
        border: none;
        padding: 10px 20px;
        font-size: 14px;
        font-weight: 500;
        font-family: 'poppins';
        margin-top: 30px;
        transition: 0.2s ease;
    }

    #park_edge_payment_btn:hover {
        background-color: white;
        color: #1D1E20;
    }



    #park_edge_amenities {
        width: 100%;
        height: 120vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
        padding-bottom: 20px;
    }

    #park_edge_amenities_img {
        width: 100%;
        height: 50%;
        background-image: url(https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=503,h=622,fit=crop/YBg4JBL97EcZG7Zq/3-1-AR0b9NjXkXF5DO21.jpeg);
        /* background-size: cover;
        background-attachment: fixed; */
    }

    #park_edge_amenities_content {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        font-family: 'poppins';
        padding: 30px 10px;
        text-align: center;
        color: #1D1E20;
        gap: 15px;
    }

    #park_edge_amenities_icn_div {
        width: 90%;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        /* Ensures that items are spaced out evenly */
        align-items: center;
        flex-wrap: wrap;
        gap: 5px;
        /* Sets a gap of 20px between the items */
    }

    #park_edge_amenities_icn_div>span {
        flex-basis: calc(33.333% - 20px);
        /* Subtract the gap to ensure proper spacing */
        display: flex;
        justify-content: center;
        /* Center content within each item if necessary */
        align-items: center;
        font-size: 14px;
        line-height: 1.2;
    }



    #park_edge_contact {
        width: 100%;
        height: 100vh;
        background-color: #1D1E20;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
    }

    #park_edge_contact_content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 20px;
        padding-bottom: 30px;
    }

    #park_edge_contact_hdng {
        color: #f7f7f7;
        font-size: 30px;
        font-weight: 400;
        font-family: 'poppins';
        text-align: left;
    }

    #park_edge_contact_txt {
        color: #f7f7f7;
        font-size: 16px;
        font-weight: 300;
        font-family: 'poppins';
    }

    hr {
        width: 90%;
        background-color: #f7f7f7;
        height: 7px;
        opacity: 1;
        margin-top: 5%;
    }

    #park_edge_social_div_1 {
        display: none;
    }

    #park_edge_social_div_2 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        color: #f7f7f7;
        font-size: 15px;
        font-weight: 300;
        font-family: 'poppins';
        padding-top: 20px;
    }

    #park_edge_contact_form {
        width: 100%;
    }

}
#awards_section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 70px 20px;
    background-color: #f8f8f8;
}

#awards_sbhdng {
    color: #083555;
    font-size: 18px;
    font-family: 'poppins';
    font-weight: 600;
}

#awards_hdng {
    color: #083555;
    font-size: 50px;
    font-family: 'raleway';
    font-weight: 800;
}



#awards_div {
    width: 100%;
    padding: 50px 0px;
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;
    background-color: transparent;
    box-shadow: none;
}

.awards_card {
    width: 20%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: none;
    background-color: transparent;
    box-shadow: none;
}

#awards_card_img {
    width: 100%;
    height: 50%;
    background-color: transparent;
    background-size: cover;
}






@media screen and (max-width: 450px) {

    #awards_section {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px 20px;
        background-color: #f8f8f8;
    }

    #awards_hdng {
        color: #083555;
        font-size: 35px;
        font-family: 'raleway';
        font-weight: 800;
    }



    #awards_div {
        width: 100%;
        height: 250px;
        padding: 0px;
        margin-top: 0px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        gap: 0px;
        background-color: transparent;
        box-shadow: none;
    }

    .awards_card {
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        border: none;
        background-color: transparent;
        box-shadow: none;
    }



}
#event_about_section {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: row;
    padding-bottom: 20px;
    background-color: whitesmoke;
}

#event_about_nmbrs_div {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#member_nmbr {
    color: #0049AC;
    font-size: 100px;
    font-family: 'poppins';
    font-weight: 800;
    line-height: 0.9;
}

#member_txt {
    color: #4e4e4e;
    font-size: 20px;
    font-family: 'poppins';
    font-weight: 300;
}

#event_about_img {
    width: 85%;
}

#event_about_txt_div {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 70px;
    gap: 10px;
}

#event_about_hdng {
    color: #083555;
    font-size: 50px;
    font-family: 'raleway';
    font-weight: 800;
}

#event_about_txt {
    color: #4e4e4e;
    font-size: 16px;
    font-family: 'poppins';
    font-weight: 400;
    line-height: 1.4;
    padding: 5px 0px 25px 0px;
}

#event_about_highlight {
    color: #4e4e4e;
    font-size: 18px;
    font-family: 'poppins';
    font-weight: 600;
}

.points {
    color: #4e4e4e;
    font-size: 16px;
    font-family: 'poppins';
    font-weight: 400;
    line-height: 1.4;
}



@media screen and (max-width: 450px) {

    #event_about_section {
        width: 100%;
        height: 80vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 20px;
        background-color: whitesmoke;
        overflow: hidden;
    }

    #event_about_img_div {
        display: none;
    }

    #event_about_img {
        display: none;
    }

    #event_about_txt_div {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 20px;
        gap: 10px;
    }

    #event_about_hdng {
        color: #083555;
        font-size: 35px;
        font-family: 'raleway';
        font-weight: 800;
    }

    #event_about_txt {
        color: #4e4e4e;
        font-size: 14px;
        font-family: 'poppins';
        font-weight: 400;
        line-height: 1.4;
        padding: 5px 0px 25px 0px;
    }

    #event_about_highlight {
        color: #4e4e4e;
        font-size: 16px;
        font-family: 'poppins';
        font-weight: 600;
    }

    .points {
        color: #4e4e4e;
        font-size: 14px;
        font-family: 'poppins';
        font-weight: 400;
        line-height: 1.4;
    }

}
.market_nav_item {
    padding: 0px 10px;
    list-style: none;
}


#market_main_navbar {
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
    z-index: 2;
    position: absolute;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
    animation: slide 1s;
    padding: 0px 5%;
}

#market_main_navbarscrolled {
    visibility: hidden;
    position: absolute;
}

@keyframes slide {
    0% {
        margin-top: -70px;
        position: absolute;
    }

    100% {
        margin-top: 0px;
    }
}

#market_navbar_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    padding: 0;
    margin: 0;
}

#market_collapse_container {
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#market_logo_container {
    width: 20%;
    height: 100%;
}

#market_logo {
    width: 70%;
    height: auto;
    cursor: pointer;
}

#market_navbar {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#market_nav_item {
    padding: 0px 30px;
}

#market_nav_btn {
    background: none;
    border: none;
    font-size: 14px;
    position: relative;
    font-family: 'poppins';
    font-weight: 400;
    letter-spacing: 0px;
    color: #083555;
    transition: 0.2s ease;
}

#market_nav_btn:hover {
    color: #EF4723;
}

#market_dropdown_btn {
    background: none;
    border: none;
    font-size: 14px;
    position: relative;
    font-family: 'poppins';
    font-weight: 400;
    letter-spacing: 0px;
    color: #083555;
    box-shadow: none;
    padding: 1px 6px;
    text-transform: capitalize;
    transition: 0.2s ease;
}

#market_dropdown_btn:hover {
    color: #EF4723;
}

#market_dropdown_btn::after {
    display: none;
    visibility: hidden;
    opacity: 0;
}

#market_dropdown_menu {
    position: absolute;
    z-index: 100;
    font-size: 14px;
    font-family: 'poppins';
    font-weight: 300;
    letter-spacing: 0px;
}


#market_upper_navbar {
    width: 100%;
    background-color: #EF4723;
    padding: 7px 30px 7px 20px;
}

#market_fixed_upper_navbar {
    display: none;
}

@keyframes drop {
    0% {
        margin-top: -70px;
        box-shadow: none;
        position: absolute;
    }

    100% {
        margin-top: 0px;
        box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.35);
    }
}

#market_fixed_upper_navbarshow {
    display: block;
    width: 100%;
    background-color: #EF4723;
    padding: 10px 30px 10px 20px;
    position: fixed;
    z-index: 1000;
    animation: drop 1s;
    box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.35);
    margin: 0;
    top: 0;
}

#market_upper_nav_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#market_upper_nav {
    width: 100%;
}

.market_upper_nav_btn {
    background: none;
    border: none;
    color: white;
    font-size: 13px;
    position: relative;
    font-family: 'poppins';
    font-weight: 300;
    letter-spacing: 0px;
    /* display: flex; */
    gap: 10px;
}

#market_sign_in_btn {
    visibility: hidden;
    opacity: 0;
    background-color: #083555;
    padding: 7px 25px;
    margin: 0;
}

#market_link {
    color: #083555;
    width: 100%;
    height: 100%;
}




@media screen and (max-width: 450px) {

    .market_nav_item {
        padding: 0px 10px;
        list-style: none;
    }


    #market_main_navbar {
        width: 100%;
        height: 70px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: white;
        z-index: 2;
        position: absolute;
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
        animation: slide 1s;
        padding: 0px 0px;
    }

    #market_main_navbarscrolled {
        visibility: hidden;
        position: absolute;
    }

    @keyframes slide {
        0% {
            margin-top: -70px;
            position: absolute;
        }

        100% {
            margin-top: 0px;
        }
    }

    #market_navbar_container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 0px;
    }

    #market_collapse_container {
        width: 100%;
        height: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
        padding: 15px;
    }

    #market_logo_container {
        width: 30%;
        height: 100%;
        margin-left: 5%;
    }

    #market_logo {
        width: 100%;
        height: auto;
        cursor: pointer;
    }

    #market_navbar {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

    #market_nav_item {
        padding: 0px 30px;
    }

    #market_nav_btn {
        background: none;
        border: none;
        font-size: 14px;
        position: relative;
        font-family: 'poppins';
        font-weight: 400;
        letter-spacing: 0px;
        color: #083555;
        transition: 0.2s ease;
    }

    #market_nav_btn:hover {
        color: #EF4723;
    }

    /* #market_dropdown_btn {
        background: none;
        border: none;
        font-size: 14px;
        position: relative;
        font-family: 'poppins';
        font-weight: 400;
        letter-spacing: 0px;
        color: #083555;
        box-shadow: none;
        padding: 1px 6px;
        text-transform: capitalize;
        transition: 0.2s ease;
    }

    #market_dropdown_btn:hover {
        color: #EF4723;
    }

    #market_dropdown_btn::after {
        display: none;
        visibility: hidden;
        opacity: 0;
    }

    #market_dropdown_menu {
        position: absolute;
        z-index: 100;
        font-size: 14px;
        font-family: 'poppins';
        font-weight: 300;
        letter-spacing: 0px;
    } */


    #market_upper_navbar {
        width: 100%;
        background-color: #EF4723;
        padding: 7px 5px;
    }

    #market_upper_nav {
        width: 100%;
        justify-content: space-between;
    }

    @keyframes drop {
        0% {
            margin-top: -70px;
            box-shadow: none;
            position: absolute;
        }

        100% {
            margin-top: 0px;
            box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.35);
        }
    }

    #market_fixed_upper_navbarshow {
        display: block;
        width: 100%;
        background-color: #EF4723;
        padding: 10px 30px 10px 20px;
        position: fixed;
        z-index: 1000;
        animation: drop 1s;
        box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.35);
        margin: 0;
        top: 0;
    }

    #market_upper_nav_container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    #market_upper_nav {
        width: 100%;
    }

    .market_upper_nav_btn {
        background: none;
        border: none;
        color: white;
        font-size: 13px;
        position: relative;
        font-family: 'poppins';
        font-weight: 300;
        letter-spacing: 0px;
        /* display: flex; */
        gap: 10px;
    }

    #market_sign_in_btn {
        visibility: hidden;
        opacity: 0;
        background-color: #083555;
        padding: 7px 25px;
        margin: 0;
    }

    #market_link {
        color: #083555;
        width: 100%;
        height: 100%;
    }

}
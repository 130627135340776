#clientele_section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 70px 20px;
    background-color: white;
}

#clientele_hdng {
    color: #083555;
    font-size: 50px;
    font-family: 'raleway';
    font-weight: 800;
    padding-bottom: 30px;
}



@media screen and (max-width: 450px) {

    #clientele_section {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px 20px;
        background-color: white;
    }

    #clientele_hdng {
        color: #083555;
        font-size: 35px;
        font-family: 'raleway';
        font-weight: 800;
        padding-bottom: 30px;
    }

}
#service_section {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #f6f6f6;
    padding: 90px 20px;
}

#service_hdng {
    color: #083555;
    font-size: 50px;
    font-family: 'raleway';
    font-weight: 800;
    width: 50%;
    margin-left: 5%;
}

#service_txt {
    color: #4e4e4e;
    font-size: 16px;
    font-family: 'poppins';
    font-weight: 400;
    line-height: 1.4;
    padding: 10px 0px 25px 0px;
    margin-left: 5%;
}


#switch_btn_div {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 0;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
}

#switch_btn {
    width: 70px;
    height: 40px;
    color: #656565;
    font-size: 15px;
    font-family: 'poppins';
    font-weight: 300;
    background-color: transparent;
    border: none;
    border-bottom: solid 1px #656565;
    transition: 0.3s ease-in-out;
}

#clicked {
    width: 80px;
    height: 40px;
    color: #083555;
    font-size: 15px;
    font-family: 'poppins';
    font-weight: 300;
    background-color: transparent;
    border: none;
    border-bottom: solid 2px #EF4723;
    transition: 0.3s ease-in-out;
}


#services_div {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-self: center;
    gap: 40px;
    padding-top: 50px;
}


.service_item_div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #08355512;
    padding: 0px 40px;
    border-radius: 15px;
}

.service_item_txt_div {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px 20px;
}

.service_item_hdng {
    color: #083555;
    font-size: 30px;
    font-family: 'raleway';
    font-weight: 800;
}

.service_item_txt {
    width: 100%;
    height: 50%;
    color: #4e4e4e;
    font-size: 16px;
    font-family: 'poppins';
    font-weight: 400;
    line-height: 1.4;
    padding: 50px 0px 25px 0px;
}

.service_item_btn {
    width: 200px;
    color: white;
    font-size: 14px;
    font-family: 'poppins';
    font-weight: 300;
    background-color: white;
    border: none;
    transition: 0.3s ease-in-out;
    padding: 0;
    margin: 0;
}

.service_item_btn:hover {
    box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.2);
}

.service_item_img_div {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.service_item_img {
    width: 90%;
}

#powered_by_img {
    width: 130px;
    height: auto;
    margin-left: 5px;
}

#powered_by_txt {
    color: #656565;
    font-size: 13px;
    font-family: 'poppins';
    font-weight: 300;
    line-height: 1.4;
}



.service_item_btn #link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: white;
    margin: 0;
    padding: 0;
    padding: 7px 15px;
}



@media screen and (max-width: 450px) {

    #service_section {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: #f6f6f6;
        padding: 50px 10px;
    }

    #service_hdng {
        color: #083555;
        font-size: 35px;
        font-family: 'raleway';
        font-weight: 800;
        width: 100%;
        margin-left: 10px;
    }

    #service_txt {
        color: #4e4e4e;
        font-size: 14px;
        font-family: 'poppins';
        font-weight: 400;
        line-height: 1.4;
        padding: 15px 0px 15px 10px;
        margin-left: 0%;
    }


    #switch_btn_div {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 0;
        justify-content: center;
        align-items: center;
        padding-top: 10px;
    }

    #switch_btn {
        width: 70px;
        height: 35px;
        color: #656565;
        font-size: 14px;
        font-family: 'poppins';
        font-weight: 300;
        background-color: transparent;
        border: none;
        border-bottom: solid 1px #656565;
        transition: 0.3s ease-in-out;
    }

    #clicked {
        width: 70px;
        height: 35px;
        color: #083555;
        font-size: 14px;
        font-family: 'poppins';
        font-weight: 300;
        background-color: transparent;
        border: none;
        border-bottom: solid 2px #EF4723;
        transition: 0.3s ease-in-out;
    }


    #services_div {
        width: 95%;
        display: flex;
        flex-direction: column;
        align-self: center;
        gap: 40px;
        padding-top: 20px;
    }


    .service_item_div {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        background-color: #08355512;
        padding: 10px 0px;
        border-radius: 15px;
    }

    .service_item_txt_div {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px;
    }

    .service_item_hdng {
        color: #083555;
        font-size: 25px;
        font-family: 'raleway';
        font-weight: 800;
    }

    .service_item_txt {
        width: 100%;
        height: 130px;
        color: #656565;
        font-size: 14px;
        font-family: 'poppins';
        font-weight: 300;
        line-height: 1.4;
        padding: 30px 0px;
        margin: 0;
    }

    .service_item_btn {
        width: 170px;
        padding: 0;
        color: white;
        font-size: 13px;
        font-family: 'poppins';
        font-weight: 300;
        background-color: white;
        border: none;
        transition: 0.3s ease-in-out;
    }

    .service_item_btn #link {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        color: white;
        margin: 0;
        padding: 0;
        padding: 7px 0px;
    }

    .service_item_btn:hover {
        box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.2);
    }

    .service_item_img_div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .service_item_img {
        width: 80%;
    }

    #powered_by_img {
        width: 100px;
        height: auto;
        margin-left: 5px;
    }

    #powered_by_txt {
        color: #656565;
        font-size: 10px;
        font-family: 'poppins';
        font-weight: 300;
        line-height: 1.4;
    }

}
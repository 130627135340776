#footer_section {
    width: 100%;
    height: 60vh;
    display: flex;
    flex-direction: row;
    padding: 30px 50px;
    background-color: #083555;
}

#footer_Info_div {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 25px;
    overflow: hidden;
}

#footer_logo {
    width: 30%;
}

#footer_info_txt {
    width: 80%;
    color: rgb(201, 201, 201);
    font-size: 14px;
    font-family: 'poppins';
    font-weight: 300;
    line-height: 1.4;
    padding-bottom: 30px;
}

#footer_contact_div {
    width: 55%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 30px;
    /* background-color: aqua; */
}

#footer_links_hdng {
    color: white;
    font-size: 30px;
    font-family: 'raleway';
    font-weight: 700;
    margin: 0;
}

#footer_links_div {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
    gap: 10px;
}

.footer_links_txt {
    color: #f7f7f7;
    font-size: 14px;
    font-family: 'poppins';
    font-weight: 300;
    line-height: 1.4;
    padding: 5px;
    margin: 0;
    cursor: pointer;
}


#social_hdng {
    color: white;
    font-size: 30px;
    font-family: 'raleway';
    font-weight: 700;
    margin: 0;
    padding-top: 40px;
}

#social_icns_div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 0px 10px 10px;
    gap: 7px;
}


#social_icns_div #link {
    width: auto;
    height: auto;
    transition: 0.2s ease;
}

#social_icns_div #link:hover {
    color: white;
    transform: scale(1.2);
}



@media screen and (max-width: 450px) {

    #footer_section {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        padding: 50px 20px;
        background-color: #083555;
    }

    #footer_Info_div {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
        overflow: hidden;
    }

    #footer_logo {
        width: 40%;
    }

    #footer_info_txt {
        width: 100%;
        color: rgb(201, 201, 201);
        font-size: 14px;
        font-family: 'poppins';
        font-weight: 300;
        line-height: 1.4;
        padding-bottom: 30px;
    }

    #footer_contact_div {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 0px;
        /* background-color: aqua; */
    }

    #footer_links_hdng {
        color: white;
        font-size: 25px;
        font-family: 'raleway';
        font-weight: 700;
        margin: 0;
    }

    #footer_links_div {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 10px;
        gap: 10px;
    }

    .footer_links_txt {
        color: #f7f7f7;
        font-size: 14px;
        font-family: 'poppins';
        font-weight: 300;
        line-height: 1.4;
        padding: 5px;
        margin: 0;
        cursor: pointer;
    }


    #social_hdng {
        color: white;
        font-size: 30px;
        font-family: 'raleway';
        font-weight: 700;
        margin: 0;
        padding-top: 40px;
    }

    #social_icns_div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 20px 0px 10px 10px;
        gap: 7px;
    }

}
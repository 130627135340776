#why_section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 70px 20px;
}

#why_sbhdng {
    color: #083555;
    font-size: 18px;
    font-family: 'poppins';
    font-weight: 600;
}

#why_hdng {
    color: #083555;
    font-size: 50px;
    font-family: 'raleway';
    font-weight: 800;
}



#why_info_div {
    width: 90%;
    padding: 50px 0px;
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;
}

.info_card {
    width: 30%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: solid 2px #6565652d;
}

#info_card_img {
    width: 100%;
    height: 50%;
    background-color: #EF4723;
    background-size: cover;
}

.info_card_txt_div {
    width: 100%;
    height: 50%;
    padding: 30px 15px 20px 15px;
}

.info_card_hdng {
    color: #4c4c4c;
    font-size: 20px;
    font-family: 'poppins';
    font-weight: 600;
    text-align: center;
    padding-bottom: 15px;
}

.info_card_txt {
    color: #656565;
    font-size: 16px;
    font-family: 'poppins';
    font-weight: 300;
    line-height: 1.4;
    text-align: center;
}




@media screen and (max-width: 450px) {

    #why_section {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px 20px;
    }

    #why_sbhdng {
        color: #083555;
        font-size: 17px;
        font-family: 'poppins';
        font-weight: 600;
    }

    #why_hdng {
        color: #083555;
        font-size: 35px;
        font-family: 'raleway';
        font-weight: 800;
        text-align: center;
    }



    #why_info_div {
        width: 100%;
        padding: 20px 0px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 50px;
    }

    .info_card {
        width: 90%;
        height: 370px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: solid 2px #6565652d;
    }

    #info_card_img {
        width: 100%;
        height: 100%;
        background-color: white;
        position: relative;
    }

    #info_card_img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
    }

    .info_card_txt_div {
        width: 100%;
        height: 50%;
        padding: 30px 15px 20px 15px;
    }

    .info_card_hdng {
        color: #4c4c4c;
        font-size: 20px;
        font-family: 'poppins';
        font-weight: 600;
        text-align: center;
        padding-bottom: 5px;
    }

    .info_card_txt {
        color: #656565;
        font-size: 16px;
        font-family: 'poppins';
        font-weight: 300;
        line-height: 1.4;
        text-align: center;
    }

}
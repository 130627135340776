#proptech_parent_section {
    width: 100%;
    display: flex;
    flex-direction: column;
}

#event_section {
    width: 100%;
    height: auto;
    background: url(./file\ 640x424_001132\ 1.png);
    background-attachment: fixed;
    /* padding: 40px 0px; */
}

#event_bg {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 60px 50px;
    background: linear-gradient(to right, #083555ee 0%, #1275bbb1 90%);
    background-size: cover;
}

#event_txt_div {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
}

#event_txt_div .points {
    display: inline-flex;
    align-items: center;
    color: white;
    margin: 2px 0;
    flex: 50%;
    justify-content: start;
}

#event_hdng {
    color: white;
    font-size: 50px;
    font-family: 'raleway';
    font-weight: 800;
}

#event_hr {
    width: 20%;
    height: 2px;
    opacity: 1;
    background-color: #00FF85;
    border: none;
    margin-top: 50px;
}

#event_txt {
    color: white;
    font-size: 16px;
    font-family: 'poppins';
    font-weight: 300;
    line-height: 1.4;
    padding: 30px 0px 10px 0px;
}

#event_btn {
    width: 150px;
    height: 40px;
    color: white;
    font-size: 14px;
    font-family: 'poppins';
    font-weight: 300;
    background-color: transparent;
    border: solid 2px #00FF85;
    transition: 0.3s ease-in-out;
    margin-top: 70px;
    padding: 0;
}

#event_btn:hover {
    background-color: #00FF85;
    color: #083555;
}


#event_numbers_div {
    width: 30%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-right: 4%;
}

#event_logo {
    width: 15%;
    padding-bottom: 10px;
}

#numbers_parent_div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

}

.numbers_box {
    width: 150px;
    height: 110px;
    background-color: transparent;
    border-style: solid;
    border-color: #00FF85;
    flex-basis: 50%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

.number {
    color: white;
    font-size: 30px;
    font-family: 'poppins';
    font-weight: 800;
}

.number_txt {
    color: white;
    font-size: 17px;
    font-family: 'poppins';
    font-weight: 300;
    line-height: 1.4;
    margin: 0;
}


#event_btn #link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: white;
    margin: 0;
    padding: 0;
    padding: 7px 15px;
}

#event_btn:hover #link {
    color: #083555;
    width: 100%;
    height: 100%;
}




#club_section {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #08355527;
    overflow: hidden;
    padding: 50px;
}

#club_info_div {
    width: 55%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    flex-wrap: wrap;
    /* padding: 50px; */
}

#club_info_div .points {
    display: inline-flex;
    align-items: center;
    color: #4e4e4e;
    margin: 2px 0;
    flex: 50%;
    justify-content: start;
    font-weight: 400;
}

#club_sbhdng {
    color: #083555;
    font-size: 18px;
    font-family: 'poppins';
    font-weight: 600;
}

#club_hdng {
    color: #083555;
    font-size: 50px;
    font-family: 'raleway';
    font-weight: 800;
}

#club_txt {
    color: #4e4e4e;
    font-size: 16px;
    font-family: 'poppins';
    font-weight: 400;
    line-height: 1.4;
    padding: 30px 0px 10px 0px;
}


#club_cta_txt {
    color: #083555;
    font-size: 16px;
    font-family: 'poppins';
    font-weight: 600;
    margin-top: 60px;
}


#club_btn {
    width: 150px;
    height: 40px;
    color: white;
    font-size: 14px;
    font-family: 'poppins';
    font-weight: 400;
    background-color: #083555;
    /* border: solid 2px ; */
    border: none;
    transition: 0.3s ease-in-out;
    margin-top: 10px;
    padding: 0;
}

#club_btn:hover {
    box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.3);
}


#club_btn #link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: white;
    margin: 0;
    padding: 0;
    padding: 7px 15px;
    transition: 0.2s ease;
}

#club_btn:hover #link {
    color: white;
    width: 100%;
    height: 100%;
}



#club_img_div {
    width: 45%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    align-self: flex-end;
    /* background-color: #00FF85; */

}

#club_logo {
    width: 15%;
    left: 0;
    padding-bottom: 10px;
}

#club_img {
    width: 90%;
}



@media screen and (max-width: 450px) {

    #proptech_parent_section {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    #event_section {
        width: 100%;
        height: auto;
        background: url(./file\ 640x424_001132\ 1.png);
        background-attachment: fixed;
    }

    #event_bg {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 50px 20px;
        background: linear-gradient(to bottom, #083555ee 0%, #1275bbb1 90%);
        background-size: cover;
        gap: 30px;
    }

    #event_txt_div {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
    }

    #event_logo {
        width: 20%;
        padding-bottom: 10px;
    }

    #event_txt_div .points {
        display: inline-flex;
        align-items: center;
        color: white;
        margin: 2px 0;
        flex: 100%;
        justify-content: start;
    }

    #event_hdng {
        color: white;
        font-size: 30px;
        font-family: 'raleway';
        font-weight: 800;
    }

    #event_hr {
        width: 20%;
        height: 2px;
        opacity: 1;
        background-color: #00FF85;
        border: none;
    }

    #event_txt {
        color: white;
        font-size: 14px;
        font-family: 'poppins';
        font-weight: 300;
        line-height: 1.4;
        padding: 40px 0px 10px 0px;
    }

    #event_btn {
        width: 130px;
        height: 35px;
        color: white;
        font-size: 13px;
        font-family: 'poppins';
        font-weight: 300;
        background-color: transparent;
        border: solid 2px #00FF85;
        transition: 0.3s ease-in-out;
        margin-top: 40px;
    }

    #event_btn #link {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        color: white;
        margin: 0;
        padding: 0;
        padding: 7px 5px;
        transition: 0.2s ease;
    }

    #event_btn:hover {
        background-color: #00FF85;
        color: #083555;
    }


    #event_numbers_div {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding-right: 0%;
    }

    #numbers_parent_div {
        width: 90%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

    }

    .numbers_box {
        width: auto;
        height: 90px;
        background-color: transparent;
        border-style: solid;
        border-color: #00FF85;
        background-color: #0051ff48;
        flex-basis: 50%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
    }

    .number {
        color: white;
        font-size: 25px;
        font-family: 'poppins';
        font-weight: 800;
    }

    .number_txt {
        color: white;
        font-size: 15px;
        font-family: 'poppins';
        font-weight: 300;
        line-height: 1.4;
        margin: 0;
    }




    #club_section {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        background-color: #08355527;
        overflow: hidden;
        padding: 50px 20px;
    }

    #club_logo {
        width: 15%;
        left: 0;
        padding-bottom: 10px;
    }

    #club_info_div {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        flex-wrap: wrap;
    }

    #club_info_div .points {
        display: inline-flex;
        align-items: center;
        color: #656565;
        margin: 2px 0;
        flex: 100%;
        justify-content: start;
        font-weight: 400;
    }

    #club_sbhdng {
        color: #083555;
        font-size: 17px;
        font-family: 'poppins';
        font-weight: 600;
    }

    #club_hdng {
        color: #083555;
        font-size: 30px;
        font-family: 'raleway';
        font-weight: 800;
    }

    #club_txt {
        color: #656565;
        font-size: 14px;
        font-family: 'poppins';
        font-weight: 400;
        line-height: 1.4;
        padding: 40px 0px 10px 0px;
    }


    #club_cta_txt {
        color: #083555;
        font-size: 15px;
        font-family: 'poppins';
        font-weight: 600;
        margin-top: 60px;
        line-height: 1.4;
    }


    #club_btn {
        width: 130px;
        height: 35px;
        color: #083555;
        font-size: 13px;
        font-family: 'poppins';
        font-weight: 400;
        background-color: transparent;
        border: solid 2px #083555;
        transition: 0.3s ease-in-out;
        margin-top: 15px;
    }

    #club_btn #link {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        color: #083555;
        margin: 0;
        padding: 0;
        padding: 7px 5px;
        transition: 0.2s ease;
    }

    #club_btn:hover {
        background-color: #083555;
        color: white;
    }



    #club_img_div {
        visibility: hidden;
        width: 45%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    #club_img {
        display: none;
        height: 100%;
    }

}
/* .navbar_ {
    padding: 0px 20px;
    margin: 0px;
    box-shadow: none;

    background-color: white;
} */

/* #navbar_main {
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    margin: 0px;
    gap: 3%;
} */

/* #logo_container {
    height: 100%;
    padding: 0;
    justify-content: left;
    align-items: center;
}

#logo {
    height: 50%;
    cursor: pointer;
} */


/* #toggle_container {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    gap: 0px;
}

#toggle_btn {
    color: black;
} */

/* #navbar_nav {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-self: center;
    justify-self: center;
} */


.nav_item {
    padding: 0px 10px;
    list-style: none;
}

#country_nav_item {
    display: flex;
    flex-direction: row;
    justify-self: flex-end;
    align-self: flex-end;
    margin-left: auto;
}



/* .nav_btn {
    background: none;
    border: none;
    font-size: 13px;
    position: relative;
    font-family: 'poppins';
    font-weight: 400;
    letter-spacing: 0px;
    color: #083555;
}

.nav_btn::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 8%;
    width: 0%;
    height: 1px;
    background-color: transparent;
    transition: width 0.3s;
}

.nav_btn::before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 7%;
    width: 80%;
    height: 1px;
    background-color: transparent;
    transition: width 0.3s;
}

.nav_btn:hover::after {
    width: 85%;
    background-color: #0F8874;
    margin-top: 15px;
    transition-timing-function: ease-out;
}

.nav_btn:not(:hover)::before {
    width: 0%;
    background-color: #0F8874;
    margin-top: 15px;
    transition-timing-function: ease-out;
} */



#main_navbar {
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
    z-index: 2;
    position: absolute;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
    animation: slide 1s;
    padding: 0px 5%;
}

#main_navbarscrolled {
    visibility: hidden;
    position: absolute;
}

@keyframes slide {
    0% {
        margin-top: -70px;
        position: absolute;
    }

    100% {
        margin-top: 0px;
    }
}

#navbar_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    padding: 0;
    margin: 0;
}

#collapse_container {
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    /* overflow: hidden; */
}

#logo_container {
    width: 20%;
    height: 100%;
}

#logo {
    width: 70%;
    height: auto;
    cursor: pointer;
}

#navbar {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#nav_item {
    padding: 0px 20px;
}

#nav_btn {
    background: none;
    border: none;
    font-size: 14px;
    position: relative;
    font-family: 'poppins';
    font-weight: 400;
    letter-spacing: 0px;
    color: #083555;
    transition: 0.2s ease;
}

#nav_btn:hover {
    color: #EF4723;
}

#dropdown_btn {
    background: none;
    border: none;
    font-size: 14px;
    position: relative;
    font-family: 'poppins';
    font-weight: 400;
    letter-spacing: 0px;
    color: #083555;
    box-shadow: none;
    padding: 1px 6px;
    text-transform: capitalize;
    transition: 0.2s ease;
}

#dropdown_btn:hover {
    color: #EF4723;
}

#dropdown_btn::after {
    display: none;
    visibility: hidden;
    opacity: 0;
}

#dropdown_menu {
    position: absolute;
    z-index: 100;
    font-size: 14px;
    font-family: 'poppins';
    font-weight: 300;
    letter-spacing: 0px;
}


#upper_navbar {
    width: 100%;
    background-color: #EF4723;
    padding: 7px 30px 7px 20px;
}

#fixed_upper_navbar {
    display: none;
}

@keyframes drop {
    0% {
        margin-top: -70px;
        box-shadow: none;
        position: absolute;
    }

    100% {
        margin-top: 0px;
        box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.35);
    }
}

#fixed_upper_navbarshow {
    display: block;
    width: 100%;
    background-color: #EF4723;
    padding: 10px 30px 10px 20px;
    position: fixed;
    z-index: 1000;
    animation: drop 1s;
    box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.35);
    margin: 0;
    top: 0;
}

#upper_nav_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#upper_nav {
    width: 100%;
}


.upper_nav_btn {
    background: none;
    border: none;
    color: white;
    font-size: 13px;
    position: relative;
    font-family: 'poppins';
    font-weight: 300;
    letter-spacing: 0px;
    /* display: flex; */
    gap: 10px;
}

#sign_in_btn {
    visibility: hidden;
    opacity: 0;
    background-color: #083555;
    padding: 7px 25px;
    margin: 0;
}


#link {
    color: #083555;
    width: 100%;
    height: 100%;
}




/* Mobile screen code ...!!! */

@media screen and (max-width: 450px) {

    #upper_navbar {
        width: 100%;
        background-color: #EF4723;
        padding: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    #fixed_upper_navbar {
        display: none;
    }

    @keyframes drop {
        0% {
            margin-top: -70px;
            box-shadow: none;
            position: absolute;
        }

        100% {
            margin-top: 0px;
            box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.35);
        }
    }

    #fixed_upper_navbarshow {
        display: block;
        width: 100%;
        background-color: #EF4723;
        padding: 7px 10px;
        position: fixed;
        z-index: 1000;
        animation: drop 1s;
        box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.35);
        margin: 0;
        top: 0;
    }

    #upper_nav_container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    #upper_nav {
        width: 100%;
    }


    .upper_nav_btn {
        background: none;
        border: none;
        color: white;
        font-size: 13px;
        position: relative;
        font-family: 'poppins';
        font-weight: 300;
        letter-spacing: 0px;
        /* display: flex; */
        gap: 10px;
    }

    #country_nav_item {
        display: flex;
        flex-direction: row;
        justify-self: flex-end;
        align-self: flex-end;
        margin-left: auto;
    }


    #upper_nav .nav_item {
        padding: 0px 5px;
    }

    /* #upper_navbar {
        background-color: #EF4723;
        padding: 10px 0px;
        width: 100%;
    }

    #upper_nav_container {
        width: 100% !important;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 0px 10px;
        overflow: hidden;
    }

    #upper_nav {
        width: 50% !important;
    }

    #country_nav_item {
        display: flex;
        flex-direction: row;
        justify-self: flex-end;
        align-self: flex-end;
        margin-left: auto;
    }

    #lang_switch_nav_item {
        display: flex;
        margin-right: auto;
        padding: 0;
        justify-self: end;
    }

    #country_nav_item .upper_nav_btn {
        padding: 0;
    }


    .upper_nav_btn {
        background: none;
        border: none;
        color: white;
        font-size: 17px;
        position: relative;
        font-family: 'poppins';
        font-weight: 300;
        letter-spacing: 0px;
        gap: 0px;
    } */




    #main_navbar {
        width: 100%;
        height: 60px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: white;
        z-index: 5;
        position: absolute;
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
        animation: slide 1s;
        padding: 0px;
    }

    #main_navbarscrolled {
        visibility: hidden;
        position: absolute;
    }

    #navbar_container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 0px;
        padding: 0;
        margin: 0;
    }

    #collapse_container {
        width: auto;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        /* overflow: hidden; */
    }

    #logo_container {
        width: 30%;
        height: 100%;
        padding-left: 5%;
    }

    #logo {
        width: 100%;
        height: auto;
        cursor: pointer;
    }

    #navbar {
        width: 70% !important;
        height: 100vh;
        /* position: fixed;
        top: 0;
        left: 0; */
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 30px 20px;
        background-color: #083555;
        gap: 25px;
    }

    #nav_item {
        padding: 0px 20px;
    }

    #nav_btn {
        background: none;
        border: none;
        font-size: 14px;
        position: relative;
        font-family: 'poppins';
        font-weight: 400;
        letter-spacing: 0px;
        color: white;
        transition: 0.2s ease;
    }

    #nav_btn:hover {
        color: #EF4723;
    }

    #dropdown_btn {
        background: none;
        border: none;
        font-size: 14px;
        position: relative;
        font-family: 'poppins';
        font-weight: 400;
        letter-spacing: 0px;
        color: white;
        box-shadow: none;
        padding: 1px 6px;
        text-transform: capitalize;
    }

    #dropdown_btn::after {
        display: none;
        visibility: hidden;
        opacity: 0;
    }

    #dropdown_menu {
        position: absolute;
        z-index: 100;
        font-size: 14px;
        font-family: 'poppins';
        font-weight: 300;
        letter-spacing: 0px;
    }

}
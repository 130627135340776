body {
  scroll-behavior: smooth;
  background-color: rgb(233, 233, 233);
  width: 100% !important;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 7px;
}

body::-webkit-scrollbar-thumb {
  background-color: #083555;
  border-radius: 0px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #084978;
  width: 8px;
}

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&family=Amiri:wght@400;700&family=Crimson+Text:wght@400;600&family=DM+Sans:opsz,wght@9..40,100;9..40,200;9..40,400;9..40,700;9..40,900;9..40,1000&family=EB+Garamond:wght@500&family=Outfit:wght@900&family=Poppins:wght@100;300;400;500;600;700;800;900&display=swap');
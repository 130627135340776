#form_section {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    padding: 60px 50px;
    background-color: white;
}

#form_txt_div {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#form_sbhdng {
    color: #083555;
    font-size: 18px;
    font-family: 'poppins';
    font-weight: 600;
}

#form_hdng {
    color: #083555;
    font-size: 50px;
    font-family: 'raleway';
    font-weight: 800;
}

#form_txt {
    color: #656565;
    font-size: 16px;
    font-family: 'poppins';
    font-weight: 300;
    line-height: 1.4;
    padding: 60px 0px 10px 0px;
}


#form_div {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

div.form-outline {
    color: transparent;
    border: none;
}

.form-outline .form-control~.form-label {
    color: #656565;
    font-size: 14px;
    font-family: 'poppins';
    font-weight: 300;
    line-height: 1.4;
    /* padding: 60px 0px 10px 0px; */
}

#form_btn {
    background-color: #083555;
    margin-top: 15px;
    box-shadow: none;
    transition: 0.2s ease;
}

#form_btn:hover {
    box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.27);
}

.form-outline .form-control::placeholder {
    color: #656565c6;
    font-size: 13px;
    font-family: 'poppins';
    font-weight: 300;
    line-height: 1.4;
}


@media screen and (max-width: 450px) {

    #form_section {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        padding: 40px 20px;
        gap: 20px;
    }

    #form_txt_div {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    #form_sbhdng {
        color: #083555;
        font-size: 17px;
        font-family: 'poppins';
        font-weight: 600;
    }

    #form_hdng {
        color: #083555;
        font-size: 35px;
        font-family: 'raleway';
        font-weight: 800;
    }

    #form_txt {
        color: #4e4e4e;
        font-size: 14px;
        font-family: 'poppins';
        font-weight: 400;
        line-height: 1.4;
        padding: 30px 0px 10px 0px;
    }


    #form_div {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
    }

    .form-outline .form-control~.form-label {
        color: #656565;
        font-size: 14px;
        font-family: 'poppins';
        font-weight: 300;
        line-height: 1.5;
        border: none;
        /* padding: 60px 0px 10px 0px; */
    }

    #form_btn {
        background-color: #083555;
        margin-top: 15px;
        box-shadow: none;
        transition: 0.2s ease;
    }

    #form_btn:hover {
        box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.27);
    }

}
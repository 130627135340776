#affiliate_section {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: row;
    background: url(./Line\ 14.png), #083555;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    overflow: hidden;
    padding: 70px 50px 10px 50px;
}

#affiliate_info_div {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: hidden;
}


#affiliate_sbhdng {
    color: white;
    font-size: 18px;
    font-family: 'poppins';
    font-weight: 600;
}

#affiliate_hdng {
    color: white;
    font-size: 50px;
    font-family: 'raleway';
    font-weight: 800;
}

#affiliate_txt {
    color: whitesmoke;
    font-size: 16px;
    font-family: 'poppins';
    font-weight: 300;
    line-height: 1.4;
    padding: 40px 0px 10px 0px;
}


#affiliate_cta_txt {
    color: white;
    font-size: 16px;
    font-family: 'poppins';
    font-weight: 500;
    margin-top: 30px;
}


#affiliate_btn {
    width: 150px;
    height: 40px;
    color: white;
    font-size: 14px;
    font-family: 'poppins';
    font-weight: 400;
    background-color: transparent;
    border: solid 2px white;
    transition: 0.3s ease-in-out;
    margin-top: 5px;
    padding: 0;
}

#affiliate_btn:hover {
    background-color: white;
    color: #083555;
}


#affiliate_btn #link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: white;
    margin: 0;
    padding: 0;
    padding: 7px 15px;
}

#affiliate_btn:hover #link {
    color: #083555;
    width: 100%;
    height: 100%;
}



#affiliate_img_div {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#affiliate_img {
    width: 100%;
}



@media screen and (max-width: 450px) {

    #affiliate_section {
        width: 100%;
        height: 110vh;
        display: flex;
        flex-direction: column-reverse;
        background: url(./Line\ 14.png), #083555;
        background-size: cover;
        background-attachment: fixed;
        background-repeat: no-repeat;
        overflow: hidden;
        padding: 30px 20px;
    }

    #affiliate_info_div {
        width: 100%;
        height: 55%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }


    #affiliate_sbhdng {
        color: white;
        font-size: 17px;
        font-family: 'poppins';
        font-weight: 600;
    }

    #affiliate_hdng {
        color: white;
        font-size: 35px;
        font-family: 'raleway';
        font-weight: 800;
    }

    #affiliate_txt {
        color: whitesmoke;
        font-size: 14px;
        font-family: 'poppins';
        font-weight: 400;
        line-height: 1.4;
        padding: 40px 0px 10px 0px;
    }


    #affiliate_cta_txt {
        color: white;
        font-size: 15px;
        font-family: 'poppins';
        font-weight: 500;
        margin-top: 30px;
    }


    #affiliate_btn {
        width: 150px;
        height: 35px;
        color: white;
        font-size: 13px;
        font-family: 'poppins';
        font-weight: 400;
        background-color: transparent;
        border: solid 2px white;
        transition: 0.3s ease-in-out;
        margin-top: 5px;
    }

    #affiliate_btn #link {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        color: white;
        margin: 0;
        padding: 0;
        padding: 7px 15px;
    }

    #affiliate_btn:hover {
        background-color: white;
        color: #083555;
    }



    #affiliate_img_div {
        width: 100%;
        height: 45%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-bottom: 20px;
    }

    #affiliate_img {
        width: auto;
        height: 90%;
    }

}
.whatsapp-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    background-color: #25d366;
    /* WhatsApp green color */
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    cursor: pointer;
    z-index: 1000;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: ease-in-out 0.3s;
    animation: pulse 2s infinite;
}

.whatsapp-btn i {
    font-size: 30px;
}

@keyframes pulse {
    0% {
        /* transform: scale(1); */
        box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.4);
    }

    70% {
        /* transform: scale(1); */
        box-shadow: 0 0 0 20px rgba(37, 211, 102, 0);
    }

    100% {
        /* transform: scale(1); */
        box-shadow: 0 0 0 0 rgba(37, 211, 102, 0);
    }
}

.whatsapp-btn:hover {
    background-color: #1ebc55;
    transform: scale(1.1);
}
/* hns_home.css */

#hns_bg {
    width: 100%;
    height: 100vh;
    background-image: url(https://www.hspropertypk.com/_next/image?url=%2FProperties%2FH%26S-Residence.jpg&w=1080&q=75);
    background-size: cover;
}

#hns_home {
    width: 100%;
    height: 100%;
    background-color: rgba(11, 11, 11, 0.605);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#hns_main_hdng {
    color: whitesmoke;
    font-size: 150px;
    font-family: 'poppins';
    font-weight: 400;
}

#hns_main_txt {
    color: white;
    font-size: 20px;
    font-weight: 400;
    font-family: 'poppins';
}

.project_btn {
    background-color: whitesmoke;
    color: #1D1E20;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    font-family: 'poppins';
    margin-top: 70px;
    transition: 0.2s ease;
}

.project_btn:hover {
    background-color: #1D1E20;
    color: white;
}




#hns_about {
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 80px;
    background-color: #f9f9f9;
}

#hns_about_hdng {
    color: #1D1E20;
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 45px;
    font-weight: 400;
    font-family: 'poppins';
    text-align: center;
}

#hns_about_txt {
    color: #1D1E20;
    width: 45%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 16px;
    font-weight: 400;
    font-family: 'poppins';
    gap: 40px;
}

#hns_features_points {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    text-align: left;
}



#hns_gallery {
    width: 100%;
    height: 110vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    gap: 30px;
    padding: 30px 0px;
    background-color: white;
}

#hns_gallery_hdng {
    color: #1D1E20;
    font-size: 45px;
    font-weight: 400;
    font-family: 'poppins';
}

#hns_carousel {
    width: 90%;
    height: 70vh !important;
}

#hns_carousel_img {
    width: 100%;
    height: 100%;
}



#hns_amenities {
    width: 100%;
    height: 110vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    background-color: rgb(228, 228, 228);
}

#hns_amenities_hdng {
    color: #1D1E20;
    font-size: 45px;
    font-weight: 400;
    font-family: 'poppins';
}

#hns_amenities_txt {
    width: 90%;
    color: #1D1E20;
    font-size: 16px;
    font-weight: 400;
    font-family: 'poppins';
    text-align: center;
}

#hns_amenities_div {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
    padding-top: 30px;
}

#hns_amenities_div>span {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 16px;
    font-family: 'poppins';
    font-weight: 500;
    color: #1D1E20;
}



#hns_contact {
    width: 100%;
    height: 75vh;
    background-color: #1D1E20;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

#hns_contact_content {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
}

#hns_contact_hdng {
    color: #f7f7f7;
    font-size: 45px;
    font-weight: 400;
    font-family: 'poppins';
    text-align: center;
}

#hns_contact_txt {
    color: #f7f7f7;
    font-size: 16px;
    font-weight: 300;
    font-family: 'poppins';
}

hr {
    width: 90%;
    background-color: #f7f7f7;
    height: 7px;
    opacity: 1;
    margin-top: 17%;
}

#hns_social_div_1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    color: #f7f7f7;
    font-size: 15px;
    font-weight: 300;
    font-family: 'poppins';
}

#hns_social_div_2 {
    display: none;
}

#hns_contact_form {
    width: 30%;
}





@media screen and (max-width: 450px) {

    #hns_bg {
        width: 100%;
        height: 100vh;
        background-image: url(https://www.hspropertypk.com/_next/image?url=%2FProperties%2FH%26S-Residence.jpg&w=1080&q=75);
        background-size: cover;
    }

    #hns_home {
        width: 100%;
        height: 100%;
        background-color: rgba(11, 11, 11, 0.605);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #hns_home #hns_logo {
        width: 40%;
    }

    #hns_main_hdng {
        color: whitesmoke;
        font-size: 60px;
        font-family: 'poppins';
        font-weight: 400;
    }

    #hns_main_txt {
        color: white;
        font-size: 17px;
        font-weight: 400;
        font-family: 'poppins';
        text-align: center;
        padding: 30px 15px 0px 15px;
    }

    .project_btn {
        background-color: whitesmoke;
        color: #1D1E20;
        border: none;
        padding: 10px 20px;
        font-size: 14px;
        font-weight: 500;
        font-family: 'poppins';
        margin-top: 70px;
        transition: 0.2s ease;
    }

    .project_btn:hover {
        background-color: #1D1E20;
        color: white;
    }



    #hns_about {
        width: 100%;
        height: 80vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
        background-color: #f9f9f9;
        padding: 20px;
    }

    #hns_about_hdng {
        color: #1D1E20;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        font-weight: 400;
        font-family: 'poppins';
        text-align: center;
        line-height: 1.2;
    }

    #hns_about_txt {
        color: #1D1E20;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        font-size: 15px;
        font-weight: 400;
        font-family: 'poppins';
        text-align: center;
        gap: 40px;
    }

    #hns_features_points {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 15px;
        text-align: left;
    }



    #hns_gallery {
        width: 100%;
        height: 45vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        gap: 30px;
        padding: 30px 0px;
        background-color: white;
    }

    #hns_gallery_hdng {
        color: #1D1E20;
        font-size: 30px;
        font-weight: 400;
        font-family: 'poppins';
    }

    #hns_carousel {
        width: 90%;
        height: 80vh;
    }

    #hns_carousel_img {
        width: 100%;
        height: 100%;
    }



    #hns_amenities {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        background-color: rgb(228, 228, 228);
        padding: 50px 10px;
    }

    #hns_amenities_hdng {
        color: #1D1E20;
        font-size: 30px;
        font-weight: 400;
        font-family: 'poppins';
    }

    #hns_amenities_txt {
        width: 100%;
        color: #1D1E20;
        font-size: 15px;
        font-weight: 400;
        font-family: 'poppins';
        text-align: center;
    }

    #hns_amenities_div {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
        padding-top: 30px;
    }

    #hns_amenities_div>span {
        width: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        font-size: 16px;
        font-family: 'poppins';
        font-weight: 500;
        color: #1D1E20;
    }



    #park_edge_contact {
        width: 100%;
        height: 100vh;
        background-color: #1D1E20;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
    }

    #park_edge_contact_content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 20px;
        padding-bottom: 30px;
    }

    #park_edge_contact_hdng {
        color: #f7f7f7;
        font-size: 30px;
        font-weight: 400;
        font-family: 'poppins';
        text-align: left;
    }

    #park_edge_contact_txt {
        color: #f7f7f7;
        font-size: 16px;
        font-weight: 300;
        font-family: 'poppins';
    }

    hr {
        width: 90%;
        background-color: #f7f7f7;
        height: 7px;
        opacity: 1;
        margin-top: 5%;
    }

    #park_edge_social_div_1 {
        display: none;
    }

    #park_edge_social_div_2 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        color: #f7f7f7;
        font-size: 15px;
        font-weight: 300;
        font-family: 'poppins';
        padding-top: 20px;
    }

    #park_edge_contact_form {
        width: 100%;
    }

}
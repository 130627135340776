#lang_btn {
    padding: 0px 0px;
    list-style: none;
}

#lang_dropdown_btn {
    background: none;
    border: none;
    font-size: 14px;
    /* position: relative; */
    font-family: 'poppins';
    font-weight: 400;
    letter-spacing: 0px;
    color: white;
    box-shadow: none;
    padding: 1px 6px;
    text-transform: capitalize;
    transition: 0.2s ease;
}

#lang_dropdown_btn:hover {
    color: white;
}

#lang_dropdown_btn::after {
    display: none;
    visibility: hidden;
    opacity: 0;
}

#dropdown_menu {
    /* position: absolute; */
    /* z-index: 100; */
    font-size: 14px;
    font-family: 'poppins';
    font-weight: 300;
    letter-spacing: 0px;
    padding: 5px;
}
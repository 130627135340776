/* market_home.css */

#market_home_section {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: row;
    /* background: url(./dillon-kydd-2keCPb73aQY-unsplash\ \(1\).jpg);
    background-size: cover;
    background-attachment: fixed; */
}

#market_home_bg {
    width: 100%;
    position: fixed;
    z-index: -1;
}

#market_home_text_div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10vh 10px 20px 10px;
    background-color: #08355584;
}

#market_home_main_hdng {
    color: white;
    font-size: 40px;
    font-family: 'raleway';
    font-weight: 800;
}

#market_buttons_div {
    display: flex;
    flex-direction: row;
    gap: 25px;
    padding-top: 60px;
}

#market_learn_btn {
    width: 150px;
    height: 40px;
    color: white;
    font-size: 14px;
    font-family: 'poppins';
    font-weight: 300;
    background-color: #EF4723;
    border: solid 2px #EF4723;
    transition: 0.3s ease-in-out;
}

#market_learn_btn:hover {
    box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.3);
}

#market_call_btn {
    width: 150px;
    color: white;
    font-size: 14px;
    font-family: 'poppins';
    font-weight: 300;
    background-color: #EF4723;
    border: solid 2px #EF4723;
    transition: 0.3s ease-in-out;
}

#market_call_btn:hover {
    box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.3);
}

#market_home_img_div {
    width: 45%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

#market_home_img_div #market_rotate_img {
    width: 80%;
    height: auto;
}

@media screen and (max-width: 450px) {

    /* home section style */

    #market_home_section {
        width: 100%;
        height: 80vh;
        display: flex;
        flex-direction: column-reverse;
        overflow: hidden;
    }

    #market_home_bg {
        width: auto;
        height: 100%;
        position: fixed;
        z-index: -1;
    }

    #market_home_text_div {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10vh 10px 20px 10px;
        background-color: #08355584;
        gap: 50px;
    }

    #market_home_main_hdng {
        text-align: center;
        color: white;
        font-size: 35px;
        font-family: 'raleway';
        font-weight: 800;
    }

    #market_home_text {
        color: white;
        font-size: 16px;
        font-family: 'poppins';
        font-weight: 300;
        text-align: center;
        line-height: 1.5;
    }

    #market_buttons_div {
        display: flex;
        flex-direction: row;
        gap: 25px;
        padding-top: 5%;
    }

    #market_learn_btn {
        width: 130px;
        height: 40px;
        color: white;
        font-size: 13px;
        font-family: 'poppins';
        font-weight: 300;
        background-color: #EF4723;
        border: none;
        transition: 0.3s ease-in-out;
    }

    #market_learn_btn:hover {
        background-color: #EF4723;
    }

    #market_call_btn {
        width: 130px;
        color: white;
        font-size: 13px;
        font-family: 'poppins';
        font-weight: 300;
        background-color: #EF4723;
        border: solid 2px #EF4723;
        transition: 0.3s ease-in-out;
    }

    #market_call_btn:hover {
        box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.3);
    }

    #market_home_img_div {
        width: 100%;
        height: 45%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        padding-top: 10%;
    }

    #market_home_img {
        width: 90%;
        height: auto;
    }
}
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup {
    position: relative;
    width: 80vw;
    /* Adjusts width for responsiveness */
    max-width: 800px;
    /* Limits width on larger screens */
    aspect-ratio: 16 / 9;
    /* Maintains 16:9 aspect ratio */
    background-color: transparent;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.popup-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.popup_close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    color: white;
    border: 1px solid white;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.507);
    width: 30px;
    height: 30px;
    cursor: pointer;
    font-size: 15px;
}

@media (max-width: 768px) {
    .popup {
        width: 90vw;
        /* Wider on smaller screens */
        max-width: none;
        /* Remove max-width on mobile */
    }
}

@media (max-width: 450px) {
    .popup {
        width: 95vw;
    }
}
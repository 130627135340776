#about_section {
    width: 100%;
    height: 85vh;
    display: flex;
    flex-direction: row;
    padding-bottom: 20px;
}

#about_img_div {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#about_img {
    width: 85%;
}

#about_txt_div {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 70px;
    gap: 10px;
}

#about_hdng {
    color: #083555;
    font-size: 50px;
    font-family: 'raleway';
    font-weight: 800;
}

#about_txt {
    color: #4e4e4e;
    font-size: 16px;
    font-family: 'poppins';
    font-weight: 400;
    line-height: 1.4;
    padding: 5px 0px 25px 0px;
}

.points {
    color: #4e4e4e;
    font-size: 16px;
    font-family: 'poppins';
    font-weight: 400;
    line-height: 1.4;
}




@media screen and (max-width: 450px) {

    #about_section {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 30px 10px;
        gap: 10px;
    }

    #about_img_div {
        width: 100%;
        height: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #about_img {
        width: 80%;
    }

    #about_txt_div {
        width: 100%;
        height: 60%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 20px 10px;
        overflow: hidden;
        gap: 10px;
    }

    #about_hdng {
        color: #083555;
        font-size: 35px;
        font-family: 'raleway';
        font-weight: 800;
    }

    #about_txt {
        color: #656565;
        font-size: 14px;
        font-family: 'poppins';
        font-weight: 300;
        line-height: 1.4;
        padding: 5px 0px 5px 0px;
    }

    .points {
        color: #656565;
        font-size: 14px;
        font-family: 'poppins';
        font-weight: 300;
        line-height: 1.5;
    }

}
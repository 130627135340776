#gold_crest_bg {
    width: 100%;
    height: 110vh;
    background-image: url(./gold\ crest\ bg.png);
    background-size: cover;
}

#gold_crest_home {
    width: 100%;
    height: 100%;
    background-color: rgba(11, 11, 11, 0.605);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
}

#gold_crest_main_hdng {
    color: whitesmoke;
    font-size: 130px;
    font-family: 'poppins';
    font-weight: 600;
    line-height: 0.7;
    text-align: center;
}

#gold_crest_hdng {
    font-size: 80px;
    font-weight: 600;
}

#gold_crest_main_txt {
    color: white;
    font-size: 20px;
    font-weight: 400;
    font-family: 'poppins';
    padding-top: 50px;
}

.project_btn {
    background-color: whitesmoke;
    color: #1D1E20;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    font-family: 'poppins';
    margin-top: 70px;
    transition: 0.2s ease;
}

.project_btn:hover {
    background-color: #1D1E20;
    color: white;
}




#gold_crest_about {
    width: 100%;
    height: 60vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 80px;
}

#gold_crest_about_hdng {
    color: #1D1E20;
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 45px;
    font-weight: 700;
    font-family: 'poppins';
    text-align: center;
}

#gold_crest_about_txt {
    color: #1D1E20;
    width: 45%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 16px;
    font-weight: 400;
    font-family: 'poppins';
    gap: 40px;
}




#gold_crest_features {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #f7f7f7;
}

#gold_crest_features_txt_div {
    color: #1D1E20;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 50px;
    padding: 60px;
}

#gold_crest_features_hdng {
    font-size: 45px;
    font-weight: 400;
    font-family: 'poppins';
}

#gold_crest_features_points {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 15px;
}

#gold_crest_features_img_div {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

#gold_crest_features_img {
    width: auto;
    height: 100%;
}



#gold_crest_amenities {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#gold_crest_amenities_img {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-image: url(https://static.wixstatic.com/media/ee0244_ab25e896c305450f8370674e10e3e944~mv2.png/v1/fill/w_408,h_229,al_c,q_80,usm_0.66_1.00_0.01/ee0244_ab25e896c305450f8370674e10e3e944~mv2.png);
    background-size: cover;
}

#gold_crest_amenities_content {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 300;
    font-family: 'poppins';
    padding: 30px 60px;
    text-align: center;
    line-height: 1.2;
    color: #1D1E20;
    gap: 50px;
}

#gold_crest_amenities_icn_div {
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 0px;
    /* Sets a gap of 20px between the items */
}

#park_edge_amenities_icn_div>span {
    flex-basis: calc(33.333% - 20px);
    /* Subtract the gap to ensure proper spacing */
    display: flex;
    justify-content: center;
    /* Center content within each item if necessary */
    align-items: center;
}



#park_edge_contact {
    width: 100%;
    height: 75vh;
    background-color: #1D1E20;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

}

#park_edge_contact_content {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
}

#park_edge_contact_hdng {
    color: #f7f7f7;
    font-size: 45px;
    font-weight: 400;
    font-family: 'poppins';
    text-align: center;
}

#park_edge_contact_txt {
    color: #f7f7f7;
    font-size: 16px;
    font-weight: 300;
    font-family: 'poppins';
}

hr {
    width: 90%;
    background-color: #f7f7f7;
    height: 7px;
    opacity: 1;
    margin-top: 17%;
}

#park_edge_social_div_1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    color: #f7f7f7;
    font-size: 15px;
    font-weight: 300;
    font-family: 'poppins';
}

#park_edge_social_div_2 {
    display: none;
}

#park_edge_contact_form {
    width: 30%;
}




@media screen and (max-width: 450px) {

    #gold_crest_bg {
        width: 100%;
        height: 80vh;
        background-image: url(./gold\ crest\ bg.png);
        background-size: cover;
    }

    #gold_crest_home {
        width: 100%;
        height: 100%;
        background-color: rgba(11, 11, 11, 0.605);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 30px;
    }

    #gold_crest_main_hdng {
        color: whitesmoke;
        font-size: 60px;
        font-family: 'poppins';
        font-weight: 600;
        line-height: 0.7;
        text-align: center;
    }

    #gold_crest_hdng {
        font-size: 35px;
        font-weight: 600;
    }

    #gold_crest_main_txt {
        width: 80%;
        color: white;
        font-size: 17px;
        font-weight: 400;
        font-family: 'poppins';
        padding-top: 50px;
        text-align: center;
    }

    .project_btn {
        background-color: whitesmoke;
        color: #1D1E20;
        border: none;
        padding: 10px 20px;
        font-size: 14px;
        font-weight: 500;
        font-family: 'poppins';
        margin-top: 70px;
        transition: 0.2s ease;
    }

    .project_btn:hover {
        background-color: #1D1E20;
        color: white;
    }




    #gold_crest_about {
        width: 100%;
        height: 75vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;
        padding: 20px;
    }

    #gold_crest_about_hdng {
        color: #1D1E20;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 35px;
        font-weight: 700;
        font-family: 'poppins';
        text-align: center;
        line-height: 1.3;
    }

    #gold_crest_about_txt {
        color: #1D1E20;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        font-size: 16px;
        font-weight: 400;
        font-family: 'poppins';
        gap: 20px;
    }




    #gold_crest_features {
        width: 100%;
        height: 60vh;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: #f7f7f7;
    }

    #gold_crest_features_txt_div {
        color: #1D1E20;
        width: 70%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 40px;
        padding: 20px;
    }

    #gold_crest_features_hdng {
        font-size: 35px;
        font-weight: 400;
        font-family: 'poppins';
    }

    #gold_crest_features_points {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 15px;
    }

    #gold_crest_features_img_div {
        width: 30%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    #gold_crest_features_img {
        width: auto;
        height: 100%;
    }



    #gold_crest_amenities {
        width: 100%;
        height: 60vh;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    #gold_crest_amenities_img {
        width: 30%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        background-image: url(https://static.wixstatic.com/media/ee0244_ab25e896c305450f8370674e10e3e944~mv2.png/v1/fill/w_408,h_229,al_c,q_80,usm_0.66_1.00_0.01/ee0244_ab25e896c305450f8370674e10e3e944~mv2.png);
        background-size: cover;
    }

    #gold_crest_amenities_content {
        width: 70%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 300;
        font-family: 'poppins';
        padding: 30px 20px;
        text-align: center;
        line-height: 1.2;
        color: #1D1E20;
        gap: 30px;
    }

    #gold_crest_amenities_icn_div {
        width: 90%;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 0px;
        /* Sets a gap of 20px between the items */
    }

    #park_edge_amenities_icn_div>span {
        flex-basis: calc(30% - 20px);
        /* Subtract the gap to ensure proper spacing */
        display: flex;
        justify-content: center;
        /* Center content within each item if necessary */
        align-items: center;
    }
}
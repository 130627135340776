#club_gallery_section {
    width: 100%;
    height: fit-content;
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    gap: 40px;
}

#club_gallery_hdng {
    color: #083555;
    font-size: 50px;
    font-family: 'raleway';
    font-weight: 800;
}

#radissonblu_event {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#snp3_event {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.event_hdng {
    color: #083555;
    font-size: 30px;
    font-family: 'poppins';
    font-weight: 500;
    padding-bottom: 20px;
}

.event_images {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* 4 images per row */
    grid-gap: 20px;
    /* Adjust spacing between images */
}

.event_images img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    /* Optional: add some border radius */
    object-fit: cover;
    /* Ensure images cover the container */
}
#event_home_section {
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: row;
    /* padding-bottom: 15vh; */
    overflow: hidden;
}

#event_home_bg {
    position: fixed;
    z-index: -1;
    width: 100%;
}

#event_home_txt_div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0 10px 0px 70px;
    background: linear-gradient(to right, #083555c8 10%, #1275bbb1 90%);
}

#event_home_sbhdng {
    color: white;
    font-size: 18px;
    font-family: 'poppins';
    font-weight: 600;
}

#event_home_hdng {
    color: white;
    font-size: 85px;
    font-family: 'raleway';
    font-weight: 800;
}

#event_home_txt {
    height: 3em;
    color: white;
    font-size: 17px;
    font-family: 'poppins';
    font-weight: 300;
    padding-top: 30px;
}

#event_home_buttons_div {
    display: flex;
    flex-direction: row;
    gap: 25px;
    padding-top: 70px;
}

/* #event_home_learn_btn {
    width: 150px;
    height: 40px;
    color: white;
    font-size: 14px;
    font-family: 'poppins';
    font-weight: 300;
    background-color: transparent;
    border: solid 2px white;
    transition: 0.3s ease-in-out;
}

#event_home_learn_btn:hover {
    color: #083555;
    background-color: white;
    font-weight: 400;
} */

#event_home_join_btn {
    width: 150px;
    color: white;
    font-size: 14px;
    font-family: 'poppins';
    font-weight: 400;
    background-color: white;
    padding: 8px;
    border: solid 2px white;
    transition: 0.2s ease-in-out;
}

#event_home_join_btn:hover {
    box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.4);
}



@media screen and (max-width: 450px) {

    #event_home_section {
        width: 100%;
        height: 70vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        /* padding-bottom: 15vh; */
        overflow: hidden;
    }

    #event_home_bg {
        position: fixed;
        z-index: -1;
        width: auto;
        height: 100%;
    }

    #event_home_txt_div {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 0 10px 0px 10px;
        background: linear-gradient(to right, #083555b1 10%, #1275bb96 90%);
    }

    #event_home_sbhdng {
        color: white;
        font-size: 16px;
        font-family: 'poppins';
        font-weight: 600;
        text-align: center;
    }

    #event_home_hdng {
        color: white;
        font-size: 45px;
        font-family: 'raleway';
        font-weight: 800;
        text-align: center;
    }

    #event_home_txt {
        height: 3em;
        color: white;
        font-size: 15px;
        font-family: 'poppins';
        font-weight: 300;
        padding-top: 30px;
        text-align: center;
    }

    #event_home_buttons_div {
        display: flex;
        flex-direction: row;
        gap: 20px;
        padding-top: 70px;
    }

    /* #event_home_learn_btn {
        width: 130px;
        height: 40px;
        color: white;
        font-size: 14px;
        font-family: 'poppins';
        font-weight: 300;
        background-color: transparent;
        border: solid 2px white;
        transition: 0.3s ease-in-out;
    }

    #event_home_learn_btn:hover {
        color: #083555;
        background-color: white;
        font-weight: 400;
    } */

    #event_home_join_btn {
        width: 130px;
        color: white;
        font-size: 14px;
        font-family: 'poppins';
        font-weight: 400;
        background-color: white;
        border: solid 2px white;
        padding: 7px;
        transition: 0.2s ease-in-out;
    }

    #event_home_join_btn:hover {
        box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.4);
    }

}
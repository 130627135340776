#carousel_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 30px 0px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

#carosel {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    animation: fadeIn 0.5s linear;
}



#carosel_item {
    width: 30%;
    height: 290px;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 20px;
    overflow: hidden;
}

#carosel_item_details_div {
    width: 100%;
    height: 35%;
    display: flex;
    flex-direction: row;
    padding: 0px 10px;
}

#carosel_item_img {
    width: 100px;
    height: 100px;
    padding: 15px;
    border-radius: 100px;
}

#carosel_item_txt_div {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
}

#carosel_item_name {
    color: #083555;
    font-size: 20px;
    font-family: 'poppins';
    font-weight: 600;
    padding: 5px 0px;
    margin: 0;
}

#carosel_item_desig {
    color: #656565;
    font-size: 14px;
    font-family: 'poppins';
    font-weight: 300;
    line-height: 1;
    padding: 0px 0px 0px 0px;
    margin: 0;
}

#carosel_separator {
    width: 100%;
    height: 2px;
    opacity: 1;
    background-color: #083555;
    margin: 0;
    padding: 0;
    border: none;
}

#carosel_content_div {
    width: 100%;
    height: 40%;
    padding: 20px;
}

#carosel_content {
    color: #656565;
    font-size: 15px;
    font-family: 'poppins';
    font-weight: 400;
    line-height: 1.4;
}

#carosel_btn {
    width: 40%;
    height: 40px;
    background-color: transparent;
    border: solid 2px #083555;
    border-radius: 100px;
    color: #083555;
    font-size: 15px;
    font-family: 'poppins';
    font-weight: 400;
    line-height: 1.4;
    align-self: flex-end;
    margin-right: 10%;
    padding: 0;
    transition: 0.3s ease;
}

#carosel_btn:hover {
    background-color: #083555;
    color: white;
}

#carosel_btn #link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #083555;
    margin: 0;
    padding: 7px 15px;
    transition: 0.2s ease;
}

#carosel_btn:hover #link {
    color: white;
    width: 100%;
    height: 100%;
}


@media screen and (max-width: 450px) {

    #carousel_container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 30px 0px;
    }

    #carosel {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 10px;
    }



    #carosel_item {
        width: 100%;
        height: 35vh;
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 20px;
        overflow: hidden;
        padding-bottom: 20px;
    }

    #carosel_item_details_div {
        width: 100%;
        height: 35%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 10px;
    }

    #carosel_item_img {
        width: 100px;
        height: 100px;
        padding: 15px;
        border-radius: 100px;
    }

    #carosel_item_txt_div {
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 10px;
        gap: 5px;
    }

    #carosel_item_name {
        color: #083555;
        font-size: 20px;
        font-family: 'poppins';
        font-weight: 600;
        padding: 5px 0px;
        margin: 0;
    }

    #carosel_item_desig {
        color: #656565;
        font-size: 14px;
        font-family: 'poppins';
        font-weight: 300;
        line-height: 1;
        padding: 0px 0px 0px 0px;
        margin: 0;
    }

    #carosel_separator {
        width: 100%;
        height: 2px;
        opacity: 1;
        background-color: #083555;
        margin: 0;
        padding: 0;
        border: none;
    }

    #carosel_content_div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 50%;
        padding: 30px 20px 20px 20px;
    }

    #carosel_content {
        color: #656565;
        font-size: 14px;
        font-family: 'poppins';
        font-weight: 400;
        line-height: 1.4;
        text-align: center;
    }

    #carosel_btn {
        width: 40%;
        height: 35px;
        background-color: transparent;
        border: solid 2px #083555;
        border-radius: 100px;
        color: #083555;
        font-size: 14px;
        font-family: 'poppins';
        font-weight: 400;
        line-height: 1.4;
        align-self: center;
        margin-right: 0%;
        transition: 0.3s ease;
    }

    #carosel_btn:hover {
        background-color: #083555;
        color: white;
    }

}
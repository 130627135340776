#blogs_section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 70px 20px;
}

#blog_sbhdng {
    color: #083555;
    font-size: 18px;
    font-family: 'poppins';
    font-weight: 600;
}

#blog_hdng {
    color: #083555;
    font-size: 50px;
    font-family: 'raleway';
    font-weight: 800;
}

#blog_cards_container {
    width: 90%;
    padding: 50px 0px;
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;
}

.blogs_card {
    width: 30%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: solid 2px #6565652d;
    padding-bottom: 30px;
}

#blogs_card_image_div {
    width: 100%;
    height: 200px;
    /* size: cover; */
    cursor: pointer;
    overflow: hidden;
    /* background: linear-gradient(to bottom, rgba(255, 0, 0, 0) 50%, white 95%),
        url(./technology\ in\ real\ estate\ \(9\).png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat; */
}

#blog_card_img {
    width: 100% !important;
    height: auto;

}


.blog_card_txt_div {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 15px 0px 15px;
    overflow: hidden;
}

.blog_card_hdng {
    width: 100%;
    color: #4c4c4c;
    font-size: 20px;
    font-family: 'poppins';
    font-weight: 600;
    text-align: center;
    padding: 10px;
    /* white-space: nowrap; */
    /* text-overflow: ellipsis !important; */
    /* box-sizing: border-box; */
    overflow: hidden;
}

.blog_card_txt {
    display: flex;
    color: #656565;
    font-size: 16px;
    font-family: 'poppins';
    font-weight: 300;
    line-height: 1.4;
    text-align: center;
    padding: 0px 5px;
    margin-bottom: 40px;
    display: -webkit-box !important;
    -webkit-line-clamp: 3 !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
    text-overflow: ellipsis;
}


#blog_card_btn {
    width: 150px;
    height: 40px;
    color: white;
    font-size: 14px;
    font-family: 'poppins';
    font-weight: 300;
    background-color: #EF4723;
    border: solid 2px #EF4723;
    box-shadow: none;
    transition: 0.3s ease-in-out;
    padding: 0;
    margin: 0;
}

#blog_card_btn:hover {
    box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.2);
}


#blog_card_btn #link {
    color: white;
    width: 100%;
    height: 100%;
    transition: 0.2s ease;
}


#blog_btn {
    width: 150px;
    height: 40px;
    color: #656565;
    font-size: 14px;
    font-family: 'poppins';
    font-weight: 400;
    background-color: transparent;
    border: solid 2px #083555;
    margin-top: 20px;
    transition: 0.3s ease-in-out;
}

#blog_btn:hover {
    background-color: #083555;
    color: white;
}

#blog_btn #link {
    color: #4e4e4e;
    width: 100%;
    height: 100%;
    transition: 0.2s ease;
}

#blog_btn:hover #link {
    color: white;
    width: 100%;
    height: 100%;
}



@media screen and (max-width: 450px) {

    #blogs_section {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px 20px;
    }

    #blog_sbhdng {
        color: #083555;
        font-size: 17px;
        font-family: 'poppins';
        font-weight: 600;
    }

    #blog_hdng {
        color: #083555;
        font-size: 35px;
        font-family: 'raleway';
        font-weight: 800;
    }

    #blog_cards_container {
        width: 100%;
        padding: 30px 0px;
        margin: 0;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        gap: 30px;
    }

    .blogs_card {
        width: 90%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: solid 2px #6565652d;
        padding-bottom: 10px;
    }

    #blog_card_img {
        width: 100%;
        height: 200px;
        background-color: white;
        cursor: pointer;
        overflow: hidden;
        background-color: #EF4723;
    }

    .blog_card_txt_div {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 15px 20px 15px;
    }

    .blog_card_hdng {
        color: #4c4c4c;
        font-size: 20px;
        font-family: 'poppins';
        font-weight: 600;
        text-align: center;
        padding-bottom: 15px;
    }

    .blog_card_txt {
        color: #656565;
        font-size: 14px;
        font-family: 'poppins';
        font-weight: 300;
        line-height: 1.4;
        text-align: center;
        padding: 0;
    }

    #blog_card_btn {
        width: 150px;
        height: 40px;
        color: white;
        font-size: 14px;
        font-family: 'poppins';
        font-weight: 300;
        background-color: #EF4723;
        border: solid 2px #EF4723;
        box-shadow: none;
        transition: 0.3s ease-in-out;
        padding: 0;
    }

    #blog_card_btn:hover {
        box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.3);
    }

    #blog_btn {
        width: 150px;
        height: 40px;
        color: #656565;
        font-size: 14px;
        font-family: 'poppins';
        font-weight: 400;
        background-color: transparent;
        border: solid 2px #083555;
        margin-top: 20px;
        transition: 0.3s ease-in-out;
    }

    #blog_btn:hover {
        background-color: #083555;
        color: white;
    }

}
#market_projects_section {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    padding: 50px 20px;
    gap: 50px;
}

#market_projects_hdng {
    color: #083554;
    font-size: 50px;
    font-family: 'raleway';
    font-weight: 800;
}

#carousel {
    width: 100%;
    height: 90vh;
    padding: 0px 5%;
}

#carosel_slide {
    height: 100%;
}


.project_card {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    border-radius: 15px;
    background-color: white;
    border: 1px solid rgba(128, 128, 128, 0.346);
}

.project_card_img_div {
    width: 100%;
    height: 40%;

    background-size: cover;
    background-position: center;
}

#emaar_parkedge {
    background-image: url(https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=503,h=622,fit=crop/YBg4JBL97EcZG7Zq/3-1-AR0b9NjXkXF5DO21.jpeg);
}

#hns_residence {
    background-image: url(https://www.hspropertypk.com/_next/image?url=%2FProperties%2FH%26S-Residence.jpg&w=1080&q=75);
}

#gold_crest {
    background-image: url(https://static.wixstatic.com/media/ee0244_8c8bcf437e7c416abcb30c31c8129cd7~mv2.png/v1/fill/w_408,h_194,al_c,q_80,usm_0.66_1.00_0.01/ee0244_8c8bcf437e7c416abcb30c31c8129cd7~mv2.png);
}

#aman_golf_view {
    background-image: url(https://www.amanbuilders.com.pk/images/services/aman-golf-view.png?ver=2);
}

.project_card_content_div {
    width: 100%;
    height: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 15px 0px 15px;
    overflow: hidden;
    gap: 5px;
}

#project_logo {
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

#project_card_hdng {
    width: 100%;
    color: #083554;
    font-size: 25px;
    font-family: 'poppins';
    font-weight: 600;
    text-align: center;
    overflow: hidden;
    margin: 0;
}

#project_card_text {
    display: flex;
    color: #083554;
    font-size: 14px;
    font-family: 'poppins';
    font-weight: 300;
    line-height: 1.2;
    text-align: center;
    padding: 0px 5px;
    overflow: hidden !important;
}


#project_detail_div {
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

#project_price_box {
    width: 55%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 7px;
}

#booking_ {
    font-size: 17px;
    font-family: 'poppins';
    font-weight: 400;
    line-height: 1;
    text-align: center;
}

#project_price {
    font-size: 18px;
    font-family: 'poppins';
    font-weight: 700;
    line-height: 1;
    color: #083554;
}

#project_amenities_box {
    width: 45%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.amenities_ {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    font-family: 'poppins';
    font-weight: 400;
    gap: 7px;
}


#project_location {
    width: 100%;
    height: 5%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-family: 'poppins';
    font-weight: 400;
    color: rgb(67, 67, 67);
}

#project_btn {
    width: 100%;
    height: 7%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 15px;
    font-family: 'poppins';
    background-color: #EF4723;
    transition: 0.2s ease-in-out;
}

#project_btn:hover {
    font-size: 16px;
}

.swiper-button-next::after {
    color: #083554;
    font-size: 30px;
    padding-left: 25px;
}

.swiper-button-prev::after {
    color: #083554;
    font-size: 30px;
    padding-right: 25px;
}



@media screen and (max-width: 450px) {

    #market_projects_hdng {
        color: #083554;
        font-size: 35px;
        font-family: 'raleway';
        font-weight: 800;
        text-align: center;
    }

}
#hero_section {
    width: 100%;
    height: 150vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background-color: white;
    overflow: hidden;
    padding-top: 30px;
}


/* home section style */

#home_section {
    width: 100%;
    height: 110vh;
    display: flex;
    flex-direction: row;
    background: linear-gradient(to right, #083555, #1275BB);
    padding-bottom: 15vh;
}

#home_text_div {
    width: 55%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    padding: 0 10px 0px 70px;
}

#home_main_hdng {
    color: white;
    font-size: 55px;
    font-family: 'raleway';
    font-weight: 800;
}

#home_text {
    height: 3em;
    color: white;
    font-size: 18px;
    font-family: 'poppins';
    font-weight: 300;
}

#buttons_div {
    display: flex;
    flex-direction: row;
    gap: 25px;
    padding-top: 60px;
}

#learn_btn {
    width: 150px;
    height: 40px;
    color: white;
    font-size: 14px;
    font-family: 'poppins';
    font-weight: 300;
    background-color: transparent;
    border: solid 2px #EF4723;
    transition: 0.3s ease-in-out;
}

#learn_btn:hover {
    background-color: #EF4723;
}

#call_btn {
    width: 150px;
    color: white;
    font-size: 14px;
    font-family: 'poppins';
    font-weight: 300;
    background-color: #EF4723;
    border: solid 2px #EF4723;
    transition: 0.3s ease-in-out;
}

#call_btn:hover {
    box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.3);
}


#home_img_div {
    width: 45%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

#home_img_div #rotate_img {
    width: 80%;
    height: auto;
}


/* info section */

#info_section {
    width: 90%;
    height: 50vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    position: absolute;
    bottom: 30px;
    overflow: hidden;
    background-color: whitesmoke;
    box-shadow: 1px 1px 16px 2px rgba(0, 0, 0, 0.2);
}

.info_box {
    width: 25%;
    height: 100%;
    overflow: hidden;
}

#data_info_div {
    background: url(./data.png);
}

#cnslt_info_div {
    background: url(./concultancy.png);
}

#event_info_div {
    background: url(./event.png);
}

#trnng_info_div {
    background: url(./training.png);
}

.info_bg_div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 80%);
    padding: 5px;
}

.info_icon_div {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin: 10px 0px;
    /* background-color: aquamarine; */
}

.info_icn {
    width: 50%;
}

.info_hdng {
    color: #083555;
    font-size: 21px;
    font-family: 'poppins';
    font-weight: 600;
    letter-spacing: 0;
}

.info_txt {
    width: 90%;
    color: #4e4e4e;
    font-size: 15px;
    font-family: 'poppins';
    font-weight: 400;
    text-align: center;
    line-height: 1.4;
    padding-top: 5px;
}




@media screen and (max-width: 450px) {

    #hero_section {
        width: 100%;
        height: 260vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        overflow: hidden;
        padding-top: 50px;
    }


    /* home section style */

    #home_section {
        width: 100%;
        height: 110vh;
        display: flex;
        flex-direction: column-reverse;
        background: linear-gradient(to top, #083555, #1275BB);
        padding-bottom: 20vh;
        overflow: hidden;
    }

    #home_text_div {
        width: 100%;
        height: 55%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        padding: 15px 10px;
        overflow: hidden;
    }

    #home_main_hdng {
        text-align: center;
        color: white;
        font-size: 35px;
        font-family: 'raleway';
        font-weight: 800;
    }

    #home_text {
        color: white;
        font-size: 16px;
        font-family: 'poppins';
        font-weight: 300;
        text-align: center;
        line-height: 1.5;
    }

    #buttons_div {
        display: flex;
        flex-direction: row;
        gap: 25px;
        padding-top: 5%;
    }

    #learn_btn {
        width: 130px;
        /* height: 35px; */
        color: white;
        font-size: 13px;
        font-family: 'poppins';
        font-weight: 300;
        background-color: transparent;
        border: solid 2px #EF4723;
        transition: 0.3s ease-in-out;
    }

    #learn_btn:hover {
        background-color: #EF4723;
    }

    #call_btn {
        width: 130px;
        color: white;
        font-size: 13px;
        font-family: 'poppins';
        font-weight: 300;
        background-color: #EF4723;
        border: solid 2px #EF4723;
        transition: 0.3s ease-in-out;
    }

    #call_btn:hover {
        box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.3);
    }


    #home_img_div {
        width: 100%;
        height: 45%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        padding-top: 10%;
    }

    #home_img {
        width: 90%;
        height: auto;
    }


    /* info section */

    #info_section {
        width: 90%;
        height: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        position: absolute;
        top: 100vh;
        bottom: auto;
        overflow: hidden;
        background-color: whitesmoke;
        box-shadow: 1px 1px 16px 2px rgba(0, 0, 0, 0.2);
    }

    .info_box {
        width: 100%;
        height: auto;
        overflow: hidden;
    }

    #data_info_div {
        background: url(./data.png);
    }

    #cnslt_info_div {
        background: url(./concultancy.png);
    }

    #event_info_div {
        background: url(./event.png);
    }

    #trnng_info_div {
        background: url(./training.png);
    }

    .info_bg_div {
        display: none;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 80%);
        padding: 5px;
    }

    .info_icon_div {
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: center;
        overflow: hidden;
        margin: 10px 0px;
        /* background-color: aquamarine; */
    }

    .info_icn {
        width: 50%;
    }

    .info_hdng {
        color: #083555;
        font-size: 21px;
        font-family: 'poppins';
        font-weight: 600;
        letter-spacing: 0;
    }

    .info_txt {
        width: 90%;
        color: #656565;
        font-size: 15px;
        font-family: 'poppins';
        font-weight: 300;
        text-align: center;
        line-height: 1.4;
        padding-top: 5px;
    }

    .info_separator {
        padding: 0;
        margin: 0;
    }

}